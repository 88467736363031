// custom typefaces
import "fontsource-love-ya-like-a-sister"
import "fontsource-open-sans"
import "fontsource-open-sans/300.css"
import "fontsource-open-sans/700.css"

import "src/css/reset.css"
import "src/css/main.css"
import "src/css/form.css"
import "react-photoswipe-2/lib/photoswipe.css";
